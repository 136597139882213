import { useState } from "react";
import { NavLink } from "react-router-dom";
import "./ViewPositions.css";

const ViewPositions = () => {
  const [selectedVersion, setSelectedVersion] = useState("v3");

  const handleVersionChange = (version) => {
    setSelectedVersion(version);
  };

  return (
    <div className="section-bg-pages section-bg view-positions-pg">
      <div className="gradient-bg-circle-2"> </div>
      <div className="gradient-bg-circle"></div>
      <div className="container">
        <div className="page-width-vp">
          <div className="vp-pg">
            <div className="view-positions-heading">
              <h2>Positions</h2>
              <div className="dropdown">
                <button
                  className="btn dropdown-btn-pool dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {selectedVersion}
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleVersionChange("v2")}
                    >
                      v2
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleVersionChange("v3")}
                    >
                      v3
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            {selectedVersion === "v3" ? (
              <div className="navlink-a-btn-migrate">
                <NavLink to="/migrate" className="migrate-btn-a">
                  <button className="migrate-btn">
                    Migrate <i className="fa fa-angle-double-right fa-xs"></i>
                  </button>
                </NavLink>
                <NavLink to="/createPool">
                  <button className="new-positions-btn">
                    <i className="fa fa-plus me-1 fa-xs"></i>New Positions
                  </button>
                </NavLink>
              </div>
            ) : (
              <></>
            )}
          </div>{" "}
          <div className="selected-content">
            {selectedVersion === "v3" ? (
              <p>Your active V3 liquidity positions will appear here.</p>
            ) : (
              <p>Your active V2 liquidity positions will appear here</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPositions;
