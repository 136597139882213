// Deploying Token1...
// Token1 deployed to: 0x6A0C4D1b8fd3039D3dB6F8aE97fB27428ca43D40
// Deploying Token2...
// Token2 deployed to: 0xaec758d848ea49ebEe6d7bE717AcA1F0F8A0Aad3
// Minted tokens for deployer: 0x8bdFAD67f7A0F6e4b704951e4e6A026c77D9082A
// Deploying QiteSwap...
// QiteSwap deployed to: 0x3fa211C146292CcfC51442aE84c6593229295be1
// Deploying QitePool...
// QitePool deployed to: 0x862f4Ff9D74E90B74a40B7496Da03A41C7240084
// Creating a liquidity pool...
// Liquidity pool address: 0x83Abf777272586061c9F330c03f2393568Bd5f16
// export const swapContract = "0x3fa211C146292CcfC51442aE84c6593229295be1";
// export const poolContract = "0x376A01cEb4adD20A9F75a1aF6060AB803318D861";

// =========================== New version 2 =============================
// Starting deployment...
// Deploying Token1...
// Token1 deployed to: 0x6FF85f4c08C662ED9092251037DAbe1eB5fE97e6
// Deploying Token2...
// Token2 deployed to: 0x80265084492A691C7EA43BEc26bFB17346CbBeF6
// Minted tokens for deployer: 0x4DA08aE3D8494dd61c943bDc5b8a14FdC6a7BC01
// Deploying QiteSwap...
// QiteSwap deployed to: 0xB1D155bed9037507caD1D68EB702550E0F89B54F
// Deploying QitePool...
// QitePool deployed to: 0xad5edF0c723029Dc80cecd8f5C7e13B236e3E7a2
// Creating a liquidity pool...
// Liquidity pool address: 0xF9aEDecf6e5459A4b96AE6Ae1deA025D157aa4F9

// export const swapContract = "0xB1D155bed9037507caD1D68EB702550E0F89B54F";
// export const poolContract = "0x376A01cEb4adD20A9F75a1aF6060AB803318D861";

// =========================== New version 3 ===================================
// Starting deployment...
// Deployer Address: 0x4DA08aE3D8494dd61c943bDc5b8a14FdC6a7BC01
// Deployer Balance: 50.859587555991328734
// Deploying Token1...
// Token1 deployed to: 0xeDDA373AD95dDC6397d13FD0871D0741f9636DB2
// Deploying Token2...
// Token2 deployed to: 0x086311103A872c0E2c39072b34019c8C28F09837
// Minted tokens for deployer: 0x4DA08aE3D8494dd61c943bDc5b8a14FdC6a7BC01
// Deploying QiteSwap...
// QiteSwap deployed to: 0x59a006b4C12D05b09E75E2d4885D383C38DD267e
// Deploying QitePool...
// QitePool deployed to: 0x2D138d9F332F816d77967139332ee0EF868D21E3
// Creating a liquidity pool...
// Liquidity pool created.
// Liquidity pool address: 0x65e51cF985F06E25e65f800f01904fe8dE632192
// Deployer Final Balance: 50.848180421983958602
// Deployment complete.

// export const swapContract = "0x59a006b4C12D05b09E75E2d4885D383C38DD267e";

// ============================= New version 5 =============================
// Deployer Address: 0x84360cB957861fa98861F95e1a4cD2df439Cff7d
// Deployer Balance: 50.67800430592377636
// Deploying Token1...
// Token1 deployed to: 0xC7311066853009d26Bb8dDb563C8011C47B1DCDA
// Deploying Token2...
// Token2 deployed to: 0x2ea1671aA5b4e442aD7DD9298f7927e10015161D
// Minted tokens for deployer: 0x84360cB957861fa98861F95e1a4cD2df439Cff7d
// Deploying QiteSwap...
// QiteSwap deployed to: 0x3397e6c6D13DF2eE8baD0e87e0e9bB4E08975881
// Deploying QitePool...
// QitePool deployed to: 0xf53f75B35b924EedF5228316ae2041FE897Cc514
// Liquidity Token Address: 0x332f2F04f1985dafe20DE226d963a598778A8547
// Deployer Final Balance: 50.62961104072377636

export const swapContract = "0x3397e6c6D13DF2eE8baD0e87e0e9bB4E08975881";
