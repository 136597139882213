import * as API from "../../../Utils/Services/api";
import { useEffect, useState } from "react";
import ethImg from "../../../Assets/tinified/token-5.png";
import "./Send.css";
import { NavLink } from "react-router-dom";
export const logos = [
  {
    name: "POL",
    link: "/",
  },
  {
    name: "SHIB",
    link: "/",
  },
  {
    name: "USDP",
    link: "/",
  },
  {
    name: "SHIELDS",
    link: "/",
  },
  {
    name: "CRV",
    link: "/",
  },
  {
    name: "TUBBY",
    link: "/",
  },
  {
    name: "WBTC",
    link: "/",
  },
  {
    name: "ETH",
    link: "/",
  },
  {
    name: "USDC",
    link: "/",
  },
  {
    name: "MANA",
    link: "/",
  },
];
const Send = () => {
  const [value, setValue] = useState();
  const [tokenData, setTokenData] = useState(null);
  const [category, setCategory] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const handleChange = (e) => {
    setValue(e.target.value.replace(/[^0-9]/g, ""));
  };
  useEffect(() => {
    (async () => {
      const data = {
        page: 1,
        size: 50,
        category,
        sortBy,
        sortDirection,
      };
      const resp = await API.GetRWACurrencies(data);
      // console.log(resp);
      setTokenData(resp.data.currency);
    })();
  }, []);
  return (
    <div className="section-bg-pages">
      <div className="banner-bg ">
        <div className="flex-bannerimg">
          {logos.map((logo, index) => (
            <NavLink to={logo.link} key={index}>
              <div className={`logo-img logo-${index + 1}`}>
                <div className="logo-info">{logo.name}</div>
              </div>
            </NavLink>
          ))}
        </div>
        <div className="main-div">
          <div className="div-table">
            <div className="table-swap">
              <div className="swap-value send-swap-val mb-1">
                <div className="grey-color">You're sending</div>
                <div className="enter-val grey-color">
                  <div className="grey-color">
                    <input
                      type="text"
                      value={value}
                      onChange={handleChange}
                      min={0}
                      placeholder="$0"
                      id="number-input-send"
                      className="text-white "
                    />
                  </div>
                </div>
                <div className="button-div-send">
                  <button
                    type="button"
                    className="btn btn-modal-backdrop"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <div className="flex-class text-white ">
                      <img src={ethImg} alt="" className="modal-img-btn" />
                      ETH
                      <i className="fa fa-angle-down grey-clr"></i>
                    </div>
                  </button>

                  <div
                    className="modal "
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-scrollable text-white">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="exampleModalLabel"
                          >
                            Select a token
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          {tokenData ? (
                            tokenData.map((suggestion, index) => (
                              <div key={index} className="suggestion-item">
                                <div className="suggestion-img">
                                  <img src={suggestion.image} alt="" />
                                </div>
                                <div className="suggestion-name">
                                  {suggestion.name}
                                </div>
                                <div className="suggestion-sym">
                                  ({suggestion.symbol.toUpperCase()})
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="suggestion-item">
                              No results found
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swap-value send-swap-val-2 mt-1">
                <div className="grey-color">To</div>
                <div className="enter-val grey-color">
                  <div className="grey-color">
                    <input
                      type="text"
                      placeholder="Wallet address or ENS name"
                      id="number-input-send-div2"
                      className="text-white "
                    />
                  </div>
                </div>
              </div>{" "}
              <div className="get-started-btn ">
                <button className="text-white ">Connect wallet</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Send;
