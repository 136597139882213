import { NavLink } from "react-router-dom";
import Web3 from "web3";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Navbar.css";

const Navbar = () => {
  const [account, setAccount] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [network, setNetwork] = useState(null);
  useEffect(() => {
    const storedAccount = localStorage.getItem("account");
    const storedNetwork = localStorage.getItem("network");

    if (storedAccount && storedNetwork) {
      setAccount(storedAccount);
      setNetwork(storedNetwork);
    }
  }, []);
  const handleConnectMetamask = async () => {
    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);

      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });

        const accounts = await web3Instance.eth.getAccounts();
        // console.log("Connected Account:", accounts[0]);
        setAccount(accounts[0]);

        const networkId = await web3Instance.eth.net.getId();
        setNetwork(networkId);
        // console.log("Network ID:", networkId);

        localStorage.setItem("account", accounts[0]);
        localStorage.setItem("network", networkId);
        toast.success("Metamask connected successfully");
      } catch (error) {
        console.error("Connection error:", error);
      }
    } else {
      toast.error("Metamask not installed");
      // console.log("Metamask not installed");
    }
  };
  // const handleDisconnectMetamask = () => {
  //   setAccount(null);
  //   setNetwork(null);
  //   localStorage.removeItem("account");
  //   localStorage.removeItem("network");
  //   console.log("Wallet disconnected");
  // };
  const shortenAddress = (address) => {
    if (!address) return "";
    return `${address.slice(0, 5)}.....${address.slice(-5)}`;
  };

  return (
    <nav className="navbar navbar-expand-md">
      <div className="container-fluid">
        <NavLink className="navbar-brand" to="/">
          <img
            src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1734153508/powdex_svg_165_ym6eui.svg"
            alt=""
          />
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mb-2 mb-lg-0">
            <li className="nav-item">
              <div className="nav-link nav-explore">
                Trade
                <ul className="hover-nav">
                  <li>
                    <NavLink className="nav-link" to="/swap">
                      <i className="fa-solid fa-retweet me-1"></i> Swap
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink className="nav-link" to="/limit">
                      <i className="fa-solid fa-chart-simple me-1"></i> Limit
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink className="nav-link" to="/send">
                      <i className="fa-solid fa-arrow-up-from-bracket me-1"></i>{" "}
                      Send
                    </NavLink>
                  </li>{" "}
                  <li>
                    <NavLink className="nav-link" to="/buy">
                      <i className="fa-solid fa-wallet me-1"></i> Buy
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <div className="nav-link nav-explore">
                Explore
                <ul className="hover-nav">
                  <li>
                    <NavLink className="nav-link" to="/tokens">
                      Tokens
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink className="nav-link" to="/pooltable">
                      Pool
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="nav-link" to="/transactions">
                      Transactions
                    </NavLink>
                  </li> */}
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <div className="nav-link nav-explore">
                Pool
                <ul className="hover-nav">
                  <li>
                    <NavLink className="nav-link" to="/pool">
                      View positions
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="nav-link" to="/createPool">
                      Create liquidity
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="nav-link" to="/removeLiquidity">
                      Remove liquidity
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <form
            className="d-flex ms-auto"
            role="search"
            onSubmit={(e) => e.preventDefault()}
          >
            {/* <input
              className="form-control me-2 search-input-box"
              type="search"
              placeholder="Search tokens"
              aria-label="Search"
            /> */}
            <button
              onClick={handleConnectMetamask}
              className="btn btn-connect-wallet"
              type="submit"
            >
              {account ? shortenAddress(account) : "Connect "}
            </button>
          </form>
          <ToastContainer />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
