import { useEffect, useState, useRef } from "react";
import * as API from "../../../Utils/Services/api";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { ethers, BrowserProvider, Contract } from "ethers";
import { toast } from "react-toastify";
import {
  swapContract,
  poolContract,
} from "../../../Ethereum/Constant/addresses";
import { swapABI, poolABI, ERC20ABI } from "../../../Ethereum/Constant/abis";
import { contractInstance } from "../../../Ethereum/Connect/config";
import ethImg from "../../../Assets/tinified/token-5.png";
import "./CreatePositions.css";
import Loading from "../../Loading/Loading";

const CreatePositions = () => {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [tokenData, setTokenData] = useState(null);
  const [selectedRadio, setSelectedRadio] = useState("0.30%");
  const [selectedRadioP, setSelectedRadioP] = useState("93%");
  const [value, setValue] = useState();
  const [value2, setValue2] = useState();
  const [depositVal, setDepositVal] = useState();
  const [depositVal2, setDepositVal2] = useState();
  const [estimateETHWValue, setEstimateETHWValue] = useState("");
  const [estimateTokenValue, setEstimateTokenValue] = useState("");
  const [selectToken, setSelectToken] = useState(null);
  const [selectedToken, setSelectedToken] = useState(null);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const [category, setCategory] = useState("");
  const [newPoolAddress, setNewPoolAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [depositloader, setDepositloader] = useState(false);
  const [checkPoolExist, setCheckPoolExist] = useState(false);
  const debounceTimeout = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    (async () => {
      const data = {
        page: 1,
        size: 0,
        category,
        sortBy,
        sortDirection,
      };
      const resp = await API.GetRWACurrencies(data);
      setTokenData(resp.data.currency);
    })();
  }, []);

  const selectHandler = async (e) => {
    try {
      const tokenContract = await contractInstance(e.target.value, ERC20ABI);
      const name = await tokenContract.name();
      const symbol = await tokenContract.symbol();

      if (name && symbol) {
        setTokenData([
          { image: "", name, symbol, contractAddress: e.target.value },
        ]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleChange = (e) => {
    setValue(e.target.value.replace(/[^0-9]/g, ""));
  };
  const handleChange2 = (e) => {
    setValue2(e.target.value.replace(/[^0-9]/g, ""));
  };
  const handleDepositValChange = (e) => {
    setDepositVal(e.target.value.replace(/[^0-9]/g, ""));
  };
  const handleToggle = () => {
    setToggle(!toggle);
  };
  const handleGoBack = () => {
    navigate(-1);
  };
  const percentageMapping = {
    0.01: "Not created",
    0.05: "0%",
    "0.30": "93%",
    "1.00": "7%",
  };
  const handleSelection = (event) => {
    const value = event.target.value;
    setSelectedRadio(`${value}%`);
    setSelectedRadioP(percentageMapping[value]);
  };

  const saveHandler = async () => {
    if (!selectToken) return;
    const tokenContract = await contractInstance(selectToken, ERC20ABI);
    const name = await tokenContract.name();
    const symbol = await tokenContract.symbol();
    setSelectedToken({
      contractAddress: selectToken,
      name,
      symbol,
    });

    // Check if tokens pair created for selected token
    const swapInstance = await contractInstance(swapContract, swapABI);
    const poolAddress = await swapInstance.getPair(selectToken);
    poolAddress !== "0x0000000000000000000000000000000000000000"
      ? setCheckPoolExist(true)
      : setCheckPoolExist(false);
    // setSelectedToken(selectToken);
    setSelectToken(null);
  };

  const createPairHandler = async () => {
    if (checkPoolExist) return toast.info("Pair already exist");
    try {
      setLoading(true);
      // if (!window.ethereum) {
      //   toast.warn("MetaMask not detected. Please install it!");
      //   return;
      // }

      // const provider = new BrowserProvider(window.ethereum);
      // await provider.send("eth_requestAccounts", []);
      // const signer = await provider.getSigner();
      // const contract = new Contract(swapContract, swapABI, signer);
      const contract = await contractInstance(swapContract, swapABI);

      // const account = await contract.getPair(
      //   "0x16b6758e952b24f35b94d3ef38a9b6c371431afd"
      // );

      const tx = await contract.createPool(
        selectedToken.contractAddress,
        `ETHW/${selectedToken.symbol}`,
        `ETHW/${selectedToken.symbol}`
      );

      toast.info("Transaction sent! Waiting for confirmation...");
      const receipt = await tx.wait();
      // const poolCreatedAddress = receipt.logs[0].address;
      // setNewPoolAddress(poolCreatedAddress);
      setCheckPoolExist(true);
      toast.success("Pair created successfully!");
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Error: " + error.message);
      setLoading(false);
    }
  };

  const depositHandler = async () => {
    try {
      setDepositloader(true);
      // if (!window.ethereum) {
      //   toast.warn("MetaMask not detected. Please install it!");
      //   return;
      // }

      // const provider = new BrowserProvider(window.ethereum);
      // await provider.send("eth_requestAccounts", []);
      // const signer = await provider.getSigner();
      // const contract = new Contract(poolContract, poolABI, signer);
      // const tokenContract = new Contract(selectedToken, ERC20ABI, signer);
      // console.log(
      //   "selectedToken.contractAddress",
      //   selectedToken.contractAddress
      // );
      const swapInstance = await contractInstance(swapContract, swapABI);
      const poolAddress = await swapInstance.getPair(
        selectedToken.contractAddress
      );
      const contract = await contractInstance(poolAddress, poolABI);
      const tokenContract = await contractInstance(
        selectedToken.contractAddress,
        ERC20ABI
      );
      const depositAmount1 = ethers.parseEther(estimateETHWValue);
      const depositAmount2 = ethers.parseEther(estimateTokenValue);

      const approveTx = await tokenContract.approve(
        poolAddress,
        depositAmount2
      );
      toast.info("Approve transaction sent! Waiting for confirmation...");
      await approveTx.wait();
      toast.success("Approval successfull!");

      toast.info("Please confirm again for the transaction in metamask!");
      const tx = await contract.addLiquidity(depositAmount2, {
        value: depositAmount1,
      });
      toast.info("Transaction sent! Waiting for confirmation...");
      await tx.wait();
      toast.success("Liquidity pool created successfully!");
      setDepositloader(false);
      // console.log(
      //   "ETHW, Token",
      //   await contract.ethwReserve(),
      //   await contract.tokenReserve()
      // );
    } catch (err) {
      console.log(err.message);
      toast.error(`ERROR::${err.message}`);
      setDepositloader(false);
    }
  };

  /// Estimate ETHW value from token input value
  const handleTokenInput = (e) => {
    // setValue(e.target.value.replace(/[^0-9]/g, ""));

    const value = e.target.value !== "" ? e.target.value : "";
    setEstimateTokenValue(value);
    if (!value) {
      setEstimateETHWValue("");
      return;
    }

    if (!selectedToken) return;

    // Clear previous timeout
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set new debounce timeout
    debounceTimeout.current = setTimeout(async () => {
      try {
        const swapInstance = await contractInstance(swapContract, swapABI);
        const poolAddress = await swapInstance.getPair(
          selectedToken.contractAddress
        );
        const contract = await contractInstance(poolAddress, poolABI);
        const expectedTokenOut = await contract.getRequiredEthwAmount(
          ethers.parseEther(value)
        );
        setEstimateETHWValue(ethers.formatEther(String(expectedTokenOut)));
      } catch (err) {
        if (err.reason === "Insufficient pool reserves") return;
        if (err.reason === "Panic due to DIVIDE_BY_ZERO(18)")
          return toast.error("Liquidity pool not exist!");
        toast.error(err.reason);
      }
    }, 500);
  };

  /// Estimate Token value from ETHW input value
  const handleETHWInput = (e) => {
    // setValue(e.target.value.replace(/[^0-9]/g, ""));
    const value = e.target.value !== "" ? e.target.value : "";
    setEstimateETHWValue(value);
    if (!value) {
      setEstimateTokenValue("");
      return;
    }
    if (!selectedToken) return;

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(async () => {
      try {
        const swapInstance = await contractInstance(swapContract, swapABI);
        const poolAddress = await swapInstance.getPair(
          selectedToken.contractAddress
        );
        const contract = await contractInstance(poolAddress, poolABI);
        const expectedTokenOut = await contract.getRequiredTokenAmount(
          ethers.parseEther(value)
        );
        setEstimateTokenValue(ethers.formatEther(String(expectedTokenOut)));
      } catch (err) {
        if (err.reason === "Insufficient pool reserves") return;
        if (err.reason === "Panic due to DIVIDE_BY_ZERO(18)")
          return toast.error("Liquidity pool not exist!");
        toast.error(err.reason);
      }
    }, 500);
  };

  return (
    <div
      className="create-positions-ht view-positions-pg add-liquidity-pg"
      style={{ height: selectedToken && checkPoolExist ? "100%" : "100vh" }}
    >
      <div className="gradient-bg-circle-2"> </div>
      <div className="gradient-bg-circle"></div>
      <div className="container">
        <div className="page-width-vp">
          <div className="createpositons-pg ">
            <div className="migrate-pg-heading bg-none">
              <div>
                <i onClick={handleGoBack} className="fa fa-arrow-left"></i>
              </div>
              <div className="add-liquidity-heading"> Add liquidity</div>
              <div>
                {(value?.length > 0 ||
                  value2?.length > 0 ||
                  depositVal?.length > 0) && (
                  <div className="clear-all-btn">
                    <button
                      onClick={() => {
                        setValue("");
                        setValue2("");
                        setDepositVal("");
                      }}
                    >
                      Clear all
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="migrate-pg-body bg-none">
              <p>Select pair</p>
              <div className="div-add-positons-btn">
                <div className="add-postions-form-btn selected-token-btn add-pos-btn">
                  <button
                    type="button"
                    // className="btn btn-modal-backdrop"
                    className="btn backdrop-btn-css backdrop-no-modal-btn"
                    // data-bs-toggle="modal"
                    // data-bs-target="#exampleModal"
                  >
                    <div className="flex-class text-white ">
                      <div>
                        <img
                          src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1733220306/B870CB730078368E_tedoei.webp"
                          alt=""
                          className="modal-img-btn me-2 mb-1"
                        />
                        ETHW
                      </div>{" "}
                      {/* <div>
                        {" "}
                        <i className="fa fa-angle-down grey-clr"></i>
                      </div> */}
                    </div>
                  </button>

                  {/* <div
                    className="modal "
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-scrollable text-white">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="exampleModalLabel"
                          >
                            Select a token
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          Lorem, ipsum dolor sit amet consectetur adipisicing
                          elit. Quia nihil magnam eos veniam in possimus odit
                          sequi non eius, illum laboriosam aperiam nobis sequi
                          non eius, illum laboriosam aperiam nobis reprehenderit
                          deserunt quibusdam facere saepe tenetur mollitia.
                          Lorem, ipsum dolor sit amet consectetur adipisicing
                          elit. Quia nihil magnam eos veniam in possimus odit
                          sequi non eius, illum laboriosam aperiam nobis
                          reprehenderit deserunt quibusdam facere saepe tenetur
                          mollitia.
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                          <button type="button" className="btn btn-primary">
                            Save changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="add-postions-form-btn select-token-btn">
                  <button
                    type="button"
                    className="btn add-liquidity-sel-token modal-btn-back-css"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal2"
                  >
                    {selectedToken ? (
                      <>
                        <div className="flex-class text-white ">
                          <div>
                            <img
                              src={selectedToken.image}
                              alt=""
                              className="modal-img-btn me-2 mb-1"
                            />
                            {selectedToken.symbol}
                          </div>{" "}
                          <div>
                            {" "}
                            <i className="fa fa-angle-down grey-clr"></i>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex-class text-white ">
                          Select Token
                          <i className="fa fa-angle-down grey-clr"></i>
                        </div>
                      </>
                    )}
                  </button>

                  <div
                    className="modal "
                    id="exampleModal2"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-scrollable text-white">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="exampleModalLabel"
                          >
                            Select a token
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <input
                            type="text"
                            name="userToken"
                            placeholder="Enter contract address"
                            className="form-control search-input-box search-input-box-2 pt-2 pb-2 mb-2"
                            // value={selectToken}
                            onChange={(e) => setSelectToken(e.target.value)}
                            autocomplete="off"
                          />
                          {tokenData ? (
                            tokenData.map((suggestion, index) => (
                              <div key={index} className="suggestion-item">
                                <div className="suggestion-img">
                                  <img src={suggestion.image} alt="" />
                                </div>
                                <div className="suggestion-name">
                                  {suggestion.name}
                                </div>
                                <div className="suggestion-sym">
                                  ({suggestion.symbol.toUpperCase()})
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="suggestion-item">
                              No results found
                            </div>
                          )}
                        </div>
                        <div className="modal-footer get-started-btn ok-btn">
                          <button
                            type="button"
                            className="btn text-white "
                            data-bs-dismiss="modal"
                            onClick={saveHandler}
                          >
                            Ok
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="get-started-btn create-pair-btn mt-4"
                onClick={createPairHandler}
              >
                <button className="text-white " disabled={checkPoolExist}>
                  {loading ? (
                    <Loading />
                  ) : checkPoolExist ? (
                    "Paired"
                  ) : (
                    "Create pair"
                  )}
                </button>
              </div>
              <div className="fee-tier-div">
                <div>
                  <p>{selectedRadio} fee tier</p>
                  <p>{selectedRadioP} select</p>
                </div>
                {/* <div onClick={handleToggle}>{toggle ? "Hide" : "Edit"}</div> */}
                <div>Fee</div>
              </div>
              {/* {toggle && (
                <div className="row toggle-row">
                  {Object.keys(percentageMapping).map((key) => (
                    <div className="col-lg-3 col-md-6 col-6" key={key}>
                      <div className="fee-tier-div">
                        <input
                          type="radio"
                          id={key}
                          name="fee-tier-radio"
                          value={key}
                          onChange={handleSelection}
                        />
                        <label htmlFor={key}>
                          {key}% <br />
                          <span>{percentageMapping[key]}</span>
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              )} */}
              {/* above comment is for toggler */}
              {/* <p className="mt-3">Set price range</p>
              <div className="set-price-range">
                <div>
                  <span>Low price</span>

                  <span>
                    <div className="grey-color">
                      <input
                        type="text"
                        value={value}
                        onChange={handleChange}
                        min={0}
                        placeholder="0"
                        id="number-input-form"
                        className="text-white "
                      />
                    </div>
                  </span>
                </div>
              </div>
              <div className="set-price-range">
                <div>
                  <span>High price</span>

                  <span>
                    <div className="grey-color">
                      <input
                        type="text"
                        value={value2}
                        onChange={handleChange2}
                        min={1}
                        placeholder="1"
                        id="number-input-form"
                        className="text-white "
                      />
                    </div>
                  </span>
                </div>
              </div> */}
              {/* <p className="mt-3">Deposit amounts</p> */}
              {/* <div className="set-deposit-amt set-price-range">
                <div>
                  <input
                    type="text"
                    value={depositVal}
                    onChange={handleDepositValChange}
                    min={1}
                    placeholder="1"
                    id="number-input-form-2"
                    className="text-white "
                  />
                  $3,423
                </div>
                <div>
                  <img src={ethImg} alt="" className="ethImg" />
                </div>
              </div> */}
              {selectedToken && checkPoolExist && (
                <>
                  <p className="mt-3">Deposit amounts</p>
                  <div className="set-price-range">
                    <div>
                      <span>ETHW</span>

                      <span>
                        <div className="grey-color">
                          <input
                            type="text"
                            value={estimateETHWValue}
                            // onChange={(e) => setDepositVal(e.target.value)}
                            onChange={handleETHWInput}
                            min={0}
                            placeholder="0"
                            id="number-input-form"
                            className="text-white "
                            autoComplete="off"
                          />
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className="set-price-range">
                    <div>
                      <span>
                        {selectedToken ? selectedToken.symbol : "Select Token"}
                      </span>

                      <span>
                        <div className="grey-color">
                          <input
                            type="text"
                            value={estimateTokenValue}
                            // onChange={(e) => setDepositVal2(e.target.value)}
                            onChange={handleTokenInput}
                            min={1}
                            placeholder="1"
                            id="number-input-form"
                            autoComplete="off"
                            className="text-white "
                          />
                        </div>
                      </span>
                    </div>
                  </div>
                </>
              )}
              <div
                className="get-started-btn create-pair-btn mt-4"
                onClick={depositHandler}
              >
                <button className="text-white ">
                  {depositloader ? <Loading /> : "Deposit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePositions;
