import EthLogo from "../../Assets/tinified/token-5.png";
import UsdcLogo from "../../Assets/tinified/token-11.png";
import UniSwapLogo from "../../Assets/tinified/token-16.png";
import BitcoinLogo from "../../Assets/tinified/token-14.png";
import screenImg from "../../Assets/mobile-screenimg.jpg";
import "./Section1.css";
const Section1 = () => {
  const cryptoData = [
    {
      logo: "https://res.cloudinary.com/dbtsrjssc/image/upload/v1733220306/B870CB730078368E_tedoei.webp",
      name: "Ethereum PoW ",
      symbol: "ETHW",
      price: "$18.20 ",
      change: "-1.2%",
      changeIcon: "fa-caret-down",
      changeType: "negative",
    },
    {
      logo: UsdcLogo,
      name: "USDC",
      symbol: "USDC",
      price: "$1.00",
      change: "±0%",
      changeIcon: "fa-caret-up",
      changeType: "positive",
    },
    {
      logo: "https://res.cloudinary.com/dbtsrjssc/image/upload/v1733907786/logo_kxriif.png",
      name: "ETHW Dex Token ",
      symbol: "EWT",
      price: "$4.75",
      change: "+1.8%",
      changeIcon: "fa-caret-up",
      changeType: "positive",
    },
    {
      logo: BitcoinLogo,
      name: "Bitcoin",
      symbol: "BTC",
      price: "$98,108.9",
      change: "+1.47%",
      changeIcon: "fa-caret-up",
      changeType: "positive",
    },
  ];

  return (
    <div className="section-bg">
      <div className="container">
        <div className="section-1-pg">
          <h1 className="text-white ">Direct Access to PoW DeFi</h1>
          <div className="row">
            <div className="col-lg-6">
              <div className="col-half-div">
                <button className="icon-button">
                  <span className="button-content">
                    <i className="fa fa-solid fa-laptop wallet-icon"></i> Web
                    app
                  </span>
                  <i className="fa ms-2 fa-arrow-right arrow-icon"></i>
                </button>
                <h2 className="bg-none">
                  Swapping Made Simple with PoWDex <br />
                  <p style={{ fontSize: "16px", paddingTop: "10px" }}>
                    Access thousands of tokens on multiple chains powered by
                    Ethereum PoW.
                  </p>
                </h2>

                <div className="bg-none">
                  {cryptoData.map((crypto, index) => (
                    <div key={index} className="box-1-style bg-none text-white">
                      <div className="box-1-top bg-none">
                        <img
                          className="me-2"
                          src={crypto.logo}
                          alt={crypto.name}
                        />
                        {crypto.name}{" "}
                        <span className="bg-none">({crypto.symbol})</span>
                      </div>
                      <div className="box-2-top bg-none">
                        {crypto.price}{" "}
                        <span
                          className={`bg-none ${
                            crypto.changeType === "positive"
                              ? "text-success"
                              : "text-danger"
                          }`}
                        >
                          <i
                            className={`fa ${crypto.changeIcon} ms-2 me-1 bg-none`}
                          ></i>
                          {crypto.change}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="col-half-div-2">
                <button className="icon-button">
                  <span className="button-content">
                    <i className="fa fa-wallet wallet-icon"></i> Wallet
                  </span>
                  <i className="fa ms-2 fa-arrow-right arrow-icon"></i>
                </button>
                <h2 className="bg-none">
                  The wallet tailored for EthW swaps. Available on Windows and
                  Mac.
                </h2>
                <div className="image-container bg-none">
                  <img src={screenImg} alt="Uniswap Wallet" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 ">
              <div className="col-half-div-3">
                <button className="icon-button">
                  <span className="button-content">
                    <i className="fa  fa-code wallet-icon"></i>Developer docs
                  </span>
                  <i className="fa ms-2 fa-arrow-right arrow-icon"></i>
                </button>
                <h2 className="bg-none">
                  Build the next generation of open applications and tools on
                  PoWDEX.
                </h2>
              </div>
            </div>
            <div className="col-lg-6 ">
              <div className="col-half-div-4">
                <button className="icon-button">
                  <span className="button-content">
                    <i className="fa fa-chart-simple wallet-icon"></i>Liquidity
                  </span>
                  <i className="fa ms-2 fa-arrow-right arrow-icon"></i>
                </button>
                <h2 className="bg-none">
                  Provide liquidity to PoWDEX pools and earn fees on every swap.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
