import * as API from "../../Utils/Services/api";
import { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { ethers, Contract } from "ethers";
import PlaceholderLoading from "react-placeholder-loading";
import { toast } from "react-toastify";
import { swapContract } from "../../Ethereum/Constant/addresses";
import { swapABI, poolABI, ERC20ABI } from "../../Ethereum/Constant/abis";
import { contractInstance, signer } from "../../Ethereum/Connect/config";
import ethImg from "../../Assets/tinified/token-5.png";
import "./Banner.css";
import "../../App.css";
import { BrowserProvider } from "ethers";
import Loading from "../Loading/Loading";

const logos = [
  {
    name: "POL",
    link: "/",
  },
  {
    name: "SHIB",
    link: "/",
  },
  {
    name: "USDP",
    link: "/",
  },
  {
    name: "SHIELDS",
    link: "/",
  },
  {
    name: "CRV",
    link: "/",
  },
  {
    name: "TUBBY",
    link: "/",
  },
  {
    name: "WBTC",
    link: "/",
  },
  {
    name: "ETH",
    link: "/",
  },
  {
    name: "USDC",
    link: "/",
  },
  {
    name: "MANA",
    link: "/",
  },
];
const Banner = () => {
  const [value, setValue] = useState("");
  const [estimateTokenValue, setEstimateTokenValue] = useState("");
  const [estimateETHWValue, setEstimateETHWValue] = useState("");
  const [tokenData, setTokenData] = useState(null);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const [isSwapped, setIsSwapped] = useState(false);
  const [category, setCategory] = useState("");
  const [selectedToken, setSelectedToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const debounceTimeout = useRef(null);

  /// Estimate ETHW value from token input value
  const handleTokenInput = (e) => {
    // setValue(e.target.value.replace(/[^0-9]/g, ""));

    const value = e.target.value !== "" ? e.target.value : "0";
    setEstimateTokenValue(e.target.value);
    if (!selectedToken) return;

    // Clear previous timeout
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set new debounce timeout
    debounceTimeout.current = setTimeout(async () => {
      try {
        const contract = await contractInstance(swapContract, swapABI);
        const poolAddress = await contract.getPair(
          tokenData[0].contractAddress
        );

        const poolContract = await contractInstance(poolAddress, poolABI);
        const expectedTokenOut = await poolContract.getSwapOutput(
          false,
          ethers.parseEther(String(value))
        );
        setEstimateETHWValue(ethers.formatEther(String(expectedTokenOut)));
      } catch (err) {
        if (err.reason === "Panic due to DIVIDE_BY_ZERO(18)")
          return toast.error("Liquidity pool not exist!");
        toast.error(err.reason);
      }
    }, 500);
  };

  /// Estimate Token value from ETHW input value
  const handleETHWInput = (e) => {
    // setValue(e.target.value.replace(/[^0-9]/g, ""));
    const value = e.target.value !== "" ? e.target.value : "0";
    setEstimateETHWValue(e.target.value);
    if (!selectedToken) return;

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(async () => {
      try {
        const contract = await contractInstance(swapContract, swapABI);
        const poolAddress = await contract.getPair(
          tokenData[0].contractAddress
        );

        const poolContract = await contractInstance(poolAddress, poolABI);
        const expectedTokenOut = await poolContract.getSwapOutput(
          true,
          ethers.parseEther(String(value))
        );
        setEstimateTokenValue(ethers.formatEther(String(expectedTokenOut)));
      } catch (err) {
        if (err.reason === "Panic due to DIVIDE_BY_ZERO(18)")
          return toast.error("Liquidity pool not exist!");
        toast.error(err.reason);
      }
    }, 500);
  };

  const swapDivs = () => {
    setIsSwapped(!isSwapped);
  };
  useEffect(() => {
    (async () => {
      const data = {
        page: 1,
        size: 0,
        category,
        sortBy,
        sortDirection,
      };
      const resp = await API.GetRWACurrencies(data);
      setTokenData(resp.data.currency);
    })();
  }, []);

  const selectHandler = async (e) => {
    try {
      const tokenContract = await contractInstance(e.target.value, ERC20ABI);
      const name = await tokenContract.name();
      const symbol = await tokenContract.symbol();

      if (name && symbol) {
        setTokenData([
          { image: "", name, symbol, contractAddress: e.target.value },
        ]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const selectTokenHandler = (data) => {
    setSelectedToken(data);
  };

  const swapHandler = async () => {
    try {
      setLoading(true);
      const contract = await contractInstance(swapContract, swapABI);
      const poolAddress = await contract.getPair(tokenData[0].contractAddress);

      const poolContract = await contractInstance(poolAddress, poolABI);

      if (isSwapped) {
        const userSigner = await signer();
        let provider = new BrowserProvider(window.ethereum);
        const tokenContract = await contractInstance(
          selectedToken.contractAddress,
          ERC20ABI
        );
        const tokenBalance = await tokenContract.balanceOf(userSigner);
        if (
          parseFloat(ethers.formatEther(String(tokenBalance))) <=
          parseFloat(estimateTokenValue)
        ) {
          setLoading(false);
          return toast.info("Insufficient balance");
        }
        const approveTx = await tokenContract.approve(
          poolAddress,
          ethers.parseEther(estimateTokenValue)
        );
        toast.info("Approve transaction sent! Waiting for confirmation...");
        await approveTx.wait();
        toast.success("Approval successfull!");

        toast.info("Please confirm again for the transaction in metamask!");
        const tx = await poolContract.swapTokens(
          false,
          ethers.parseEther(estimateTokenValue)
        );

        toast.info("Swapping transaction initialized");
        await tx.wait();
        toast.success("Swapped successful");
        setLoading(false);
      } else {
        const userSigner = await signer();
        let provider = new BrowserProvider(window.ethereum);
        const tokenBalance = await provider.getBalance(userSigner);
        setLoading(true);
        if (
          parseFloat(ethers.formatEther(String(tokenBalance))) <=
          parseFloat(estimateETHWValue)
        ) {
          setLoading(false);
          return toast.info("Insufficient balance");
        }
        const tx = await poolContract.swapTokens(
          true,
          ethers.parseEther(estimateETHWValue),
          {
            value: ethers.parseEther(estimateETHWValue),
          }
        );

        toast.info("Swapping transaction initialized");
        await tx.wait();
        toast.success("Swapped successful");
        setLoading(false);
      }
    } catch (err) {
      console.log("err", err.message);
      toast.error(err.message);
      setLoading(false);
    }
  };

  return (
    <div className="banner-bg section-bg">
      <div className="flex-bannerimg">
        {logos.map((logo, index) => (
          <NavLink to={logo.link} key={index}>
            <div className={`logo-img logo-${index + 1}`}>
              <div className="logo-info">{logo.name}</div>
            </div>
          </NavLink>
        ))}
      </div>
      <div className="main-div">
        <div className="div-table">
          <h1>
            Swap anytime, <br />
            anywhere.
          </h1>
          <div className="table-swap">
            {!isSwapped ? (
              <>
                <div className="swap-value mb-1">
                  <div className="grey-color">Sell</div>
                  <div className="enter-val grey-color">
                    <div className="grey-color">
                      <input
                        type="text"
                        value={estimateETHWValue}
                        onChange={handleETHWInput}
                        placeholder="0"
                        id="number-input"
                        className="text-white "
                        autocomplete="off"
                        // disabled
                      />
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn backdrop-btn-css"
                        // data-bs-toggle="modal"
                        // data-bs-target="#selectmodal1"
                      >
                        <div className="flex-class text-white ">
                          <img
                            src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1733220306/B870CB730078368E_tedoei.webp"
                            alt=""
                            className="modal-img-btn"
                          />
                          ETHW
                          {/* <i className="fa fa-angle-down grey-clr"></i> */}
                        </div>
                      </button>

                      {/* <div
                      className="modal "
                      id="selectmodal1"
                      tabIndex={-1}
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-scrollable text-white">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h1
                              className="modal-title fs-5"
                              id="exampleModalLabel"
                            >
                              Select a token
                            </h1>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <div className="suggestions">
                              <input
                                type="text"
                                name="userToken"
                                placeholder="Enter contract address"
                                className="form-control search-input-box search-input-box-2 pt-2 pb-2 mb-2"
                                // value={selectToken}
                                onChange={selectHandler}
                              />

                              {tokenData ? (
                                tokenData.map((suggestion, index) => (
                                  <div key={index} className="suggestion-item">
                                    <div className="suggestion-img">
                                      <img src={suggestion.image} alt="" />
                                    </div>
                                    <div className="suggestion-name">
                                      {suggestion.name}
                                    </div>
                                    <div className="suggestion-sym">
                                      ({suggestion.symbol.toUpperCase()})
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="suggestion-item">
                                  No results found
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="modal-footer get-started-btn ok-btn">
                            <button
                              type="button"
                              className="btn text-white "
                              data-bs-dismiss="modal"
                            >
                              Ok
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    </div>
                  </div>
                  <div className="loading-div">
                    {/* <span className="grey-color">$1</span> */}
                    {/* <Loading /> */}
                    {/* <PlaceholderLoading shape="rect" width="30%" height={10} /> */}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={swapDivs}
                >
                  <i
                    className="fa fa-arrow-down "
                    style={{
                      color: "#fff",
                      cursor: "pointer",
                      backgroundColor: " rgb(32, 32, 32)",
                      padding: "10px",
                      position: "absolute",
                      borderRadius: "10px",
                    }}
                  ></i>
                </div>
                <div className="swap-value mt-1">
                  <div className="grey-color">Buy</div>
                  <div className="enter-val grey-color">
                    <div className="grey-color">
                      <input
                        type="text"
                        placeholder="0"
                        id="number-input"
                        value={estimateTokenValue}
                        className="text-white "
                        onChange={handleTokenInput}
                        autocomplete="off"
                      />
                    </div>
                    <div className="select-token-btn">
                      <button
                        type="button"
                        className="btn btn-modal-backdrop"
                        data-bs-toggle="modal"
                        data-bs-target="#selecttoken2"
                      >
                        <div
                          className="flex-class text-white "
                          style={{ textWrap: "nowrap" }}
                        >
                          {selectedToken ? (
                            <>
                              <img
                                src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1733220306/B870CB730078368E_tedoei.webp"
                                alt=""
                                className="modal-img-btn"
                              />
                              {selectedToken.symbol.toUpperCase()}
                            </>
                          ) : (
                            "Select token"
                          )}
                          <i className="fa fa-angle-down"></i>
                        </div>
                      </button>

                      <div
                        className="modal "
                        id="selecttoken2"
                        tabIndex={-1}
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-scrollable">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h1
                                className="modal-title fs-5"
                                id="exampleModalLabel"
                              >
                                Select a token
                              </h1>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <input
                                type="text"
                                name="userToken"
                                placeholder="Enter contract address"
                                className="form-control search-input-box search-input-box-2 pt-2 pb-2 mb-2"
                                // value={selectToken}
                                onChange={selectHandler}
                                autocomplete="off"
                              />
                              {tokenData ? (
                                tokenData.map((suggestion, index) => (
                                  <div
                                    key={index}
                                    className="suggestion-item"
                                    onClick={() =>
                                      selectTokenHandler(suggestion)
                                    }
                                  >
                                    <div className="suggestion-img">
                                      <img src={suggestion.image} alt="" />
                                    </div>
                                    <div className="suggestion-name">
                                      {suggestion.name}
                                    </div>
                                    <div className="suggestion-sym">
                                      ({suggestion.symbol.toUpperCase()})
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="suggestion-item">
                                  No results found
                                </div>
                              )}
                            </div>
                            <div className="modal-footer get-started-btn ok-btn">
                              <button
                                type="button"
                                className="btn text-white "
                                data-bs-dismiss="modal"
                              >
                                Ok
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </>
            ) : (
              <>
                {" "}
                <div className="swap-value mb-1">
                  <div className="grey-color">Sell</div>
                  <div className="enter-val grey-color">
                    <div className="grey-color">
                      <input
                        type="text"
                        placeholder="0"
                        id="number-input"
                        value={estimateTokenValue}
                        className="text-white "
                        onChange={handleTokenInput}
                        autocomplete="off"
                      />
                    </div>
                    <div className="select-token-btn">
                      <button
                        type="button"
                        className="btn btn-modal-backdrop"
                        data-bs-toggle="modal"
                        data-bs-target="#selecttoken3"
                      >
                        <div
                          className="flex-class text-white "
                          style={{ textWrap: "nowrap" }}
                        >
                          {selectedToken ? (
                            <>
                              <img
                                src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1733220306/B870CB730078368E_tedoei.webp"
                                alt=""
                                className="modal-img-btn"
                              />
                              {selectedToken.symbol.toUpperCase()}
                            </>
                          ) : (
                            "Select token"
                          )}
                          <i className="fa fa-angle-down "></i>
                        </div>
                      </button>

                      <div
                        className="modal "
                        id="selecttoken3"
                        tabIndex={-1}
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-scrollable">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h1
                                className="modal-title fs-5"
                                id="exampleModalLabel"
                              >
                                Select a token
                              </h1>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <input
                                type="text"
                                name="userToken"
                                placeholder="Enter contract address"
                                className="form-control search-input-box search-input-box-2 pt-2 pb-2 mb-2"
                                // value={selectToken}
                                onChange={selectHandler}
                                autocomplete="off"
                              />
                              {tokenData ? (
                                tokenData.map((suggestion, index) => (
                                  <div
                                    key={index}
                                    className="suggestion-item"
                                    onClick={() =>
                                      selectTokenHandler(suggestion)
                                    }
                                  >
                                    <div className="suggestion-img">
                                      <img src={suggestion.image} alt="" />
                                    </div>
                                    <div className="suggestion-name">
                                      {suggestion.name}
                                    </div>
                                    <div className="suggestion-sym">
                                      ({suggestion.symbol.toUpperCase()})
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="suggestion-item">
                                  No results found
                                </div>
                              )}
                            </div>
                            <div className="modal-footer get-started-btn ok-btn">
                              <button
                                type="button"
                                className="btn text-white "
                                data-bs-dismiss="modal"
                              >
                                Ok
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={swapDivs}
                >
                  <i
                    className="fa fa-arrow-down "
                    style={{
                      cursor: "pointer",
                      color: "#fff",
                      backgroundColor: " rgb(32, 32, 32)",
                      padding: "10px",
                      position: "absolute",
                      borderRadius: "10px",
                    }}
                  ></i>
                </div>{" "}
                <div className="swap-value mt-1">
                  <div className="grey-color">Buy</div>
                  <div className="enter-val grey-color">
                    <div className="grey-color">
                      <input
                        type="text"
                        value={estimateETHWValue}
                        onChange={handleETHWInput}
                        placeholder="0"
                        id="number-input"
                        className="text-white "
                        autocomplete="off"
                      />
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-modal-backdrop"
                        // data-bs-toggle="modal"
                        // data-bs-target="#selecttoken4"
                      >
                        <div className="flex-class text-white ">
                          <img
                            src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1733220306/B870CB730078368E_tedoei.webp"
                            alt=""
                            className="modal-img-btn"
                          />
                          ETHW
                          {/* <i className="fa fa-angle-down grey-clr"></i> */}
                        </div>
                      </button>

                      {/* <div
                        className="modal "
                        id="selecttoken4"
                        tabIndex={-1}
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-scrollable">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h1
                                className="modal-title fs-5"
                                id="exampleModalLabel"
                              >
                                Select a token
                              </h1>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              aboriosam aperiam nobis reprehenderit deserunt
                              quibusdam facere saepe tenetur mollitia. Lorem,
                              ipsum dolor sit amet consectetur adipisicing elit.
                              Quia nihil magnam eos veniam in possimus odit
                              sequi non eius, illum laboriosam aperiam nobis
                              reprehenderit deserunt quibusdam facere saepe
                              tenetur mollitia.
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                              <button type="button" className="btn btn-primary">
                                Save changes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  {/* <div>
                    <span className="grey-color">$0</span>
                  </div> */}
                </div>
              </>
            )}
            <div className="get-started-btn " onClick={swapHandler}>
              <button className="text-white ">
                {loading ? <Loading /> : "Get Started"}
              </button>
            </div>
          </div>
          {/* <span className="bg-none grey-clr last-span-div">
            <div>1ETHW = $0.0003</div>
            <div>
              <i className="fa fa-gas-pump me-1 fa-xs"></i>0.30ETH
            </div>
          </span> */}
        </div>
      </div>
    </div>
  );
};

export default Banner;
