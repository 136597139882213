import { BrowserProvider, Contract } from "ethers";
import { toast } from "react-toastify";

export const signer = async () => {
  if (!window.ethereum) return toast.error("Install metamask.");

  let provider = new BrowserProvider(window.ethereum);
  await provider.send("eth_requestAccounts", []);
  return await provider.getSigner();
};

export const contractInstance = async (contract, abi) => {
  const userSigner = await signer();
  return new Contract(contract, abi, userSigner);
};
