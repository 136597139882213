import "./Section3.css";

const Section3 = () => {
  return (
    <div className="section-bg">
      <div className="container">
        <div className="section-3-pg">
          <h1 className="text-white ">Connect with us</h1>
          <div className="row">
            <div className="col-lg-3">
              <div className="connect-us-box-1 col-half-div">
                <button className="icon-button">
                  <span className="button-content">
                    <i className="fa fa-solid fa-circle-question wallet-icon"></i>{" "}
                    Help center
                  </span>
                  <i className="fa ms-2 fa-arrow-right arrow-icon"></i>
                </button>
                <div className="bg-none">Get support</div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="connect-us-box-2 col-half-div">
                {" "}
                <button className="icon-button">
                  <span className="button-content">
                    <i className="fa fa-solid fa-book-open wallet-icon"></i>{" "}
                    Blog
                  </span>
                  <i className="fa ms-2 fa-arrow-right arrow-icon"></i>
                </button>
                <div className="bg-none">Insights and news from the team</div>{" "}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="connect-us-box-3 col-half-div">
                <button className="icon-button">
                  <span className="button-content">
                    <i className="fa fa-solid fa-comments wallet-icon"></i> Stay
                    connected
                  </span>
                  <i className="fa ms-2 fa-arrow-right arrow-icon"></i>
                </button>
                <div className="bg-none">
                  Follow @PoWDex on X for the latest updates
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
