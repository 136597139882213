import "./Section2.css";

const Section2 = () => {
  return (
    <div className="section-bg">
      <div className="container">
        <div className="section-2-pg">
          <div className="row">
            <div className="col-lg-6">
              <h1 className="text-white ">Trusted by millions</h1>
            </div>
            {/* <div className="col-lg-3">
              <div className="bg-section-2-box">
                <span className="bg-none">All time volume</span>
                <div className="bg-none">$500B</div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="bg-section-2-box">
                <span className="bg-none">All time swappers</span>
                <div className="bg-none">5.4M</div>
              </div>
            </div> */}
          </div>
          <div className="bottom-div-section-2">
            <div className="row mt-4">
              <div className="col-lg-6">
                <h5 className="text-white ">
                  PoWDex products are powered by the EthW Dex Protocol, the
                  leading on-chain marketplace for Ethereum PoW, facilitating
                  hundreds of millions in weekly volume across thousands of
                  tokens on the Ethereum PoW network and beyond.
                </h5>
                <button className="bg-none">
                  Learn More{" "}
                  <i className="fa fa-circle-arrow-right ms-2 bg-none"></i>
                </button>
              </div>
              <div className="col-lg-3">
                <div className="bg-section-2-box">
                  <span className="bg-none">All time volume</span>
                  <div className="bg-none">$500B</div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="bg-section-2-box">
                  <span className="bg-none">All time swappers</span>
                  <div className="bg-none">5.4M</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section2;
