import TableComponent from "../../TableComponent/TableComponent";
import "./PoolTable.css";

const PoolTable = () => {
  return (
    <div className="poolTable-pg">
      <TableComponent />
    </div>
  );
};

export default PoolTable;
