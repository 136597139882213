import axios from "axios";

const baseUrl = () => {
  if (process.env.REACT_APP_NODE_ENV === "development") {
    return process.env.REACT_APP_API_DEV_URL;
  } else if (process.env.REACT_APP_NODE_ENV === "production") {
    return process.env.REACT_APP_API_PROD_URL;
  }
};

const API = axios.create({
  baseURL: baseUrl(),
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

export const GetRWACurrencies = (data) =>
  API.get(
    `https://rwa-f1623a22e3ed.herokuapp.com/api/currencies?page=${data.page}&size=${data.size}&category=${data.category}&sortBy=${data.sortBy}&sortDirection=${data.sortDirection}`
  );
