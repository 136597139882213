import "./Loading.css";

const Loading = () => {
  return (
    <div className="mini-loader">
      <div className="main-loader-circle">
        <div className="spinner-grow text-light me-1" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <div className="spinner-grow text-light me-1" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <div className="spinner-grow text-light me-1" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default Loading;
