import { NavLink } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="section-bg">
      <div className="container">
        <div className="" style={{ paddingTop: "20px" }}>
          <div className="footer-soc">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-12">
                <div className="social-icons text-white">
                  <i className="fa fa-brands fa-reddit-alien fa-2xl"></i>
                  <i className="fa fa-brands fa-x-twitter fa-2xl"></i>
                  <i className="fa fa-brands fa-medium fa-2xl"></i>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-3"></div>
              <div className="col-lg-2 col-md-2 col-3"></div>
              <div className="col-lg-2 col-md-2 col-3">
                <div className="footer-links-heading">
                  <div>App</div>

                  <NavLink to="/swap">Trade </NavLink>
                  <br />
                  <NavLink to="/tokens">Explore </NavLink>
                  <br />
                  <NavLink to="/createPool">Pool </NavLink>
                </div>
              </div>
              {/* <div className="col-lg-2 col-md-2 col-3">
                <div className="footer-links-heading">
                  <div>Company</div>
                  <a href="/">Career </a>
                  <br />
                  <a href="/">Blog </a>
                  <br />
                  <a href="/">Brand assets </a>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-3">
                <div className="footer-links-heading">
                  <div>Protocols</div>
                  <a href="/">Vote </a>
                  <br />
                  <a href="/">Governance </a>
                  <br />
                  <a href="/">Developers </a>
                </div>
              </div>{" "} */}
              <div className="col-lg-2 col-md-2 col-3">
                <div className="footer-links-heading">
                  <div>Need Help? </div>
                  {/* <a href="/">Help center </a> */}
                  {/* <br /> */}
                  <a href="mailto:powdex@gmail.com">Contact us </a>
                  {/* <a href="/">Contact us </a> */}
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
