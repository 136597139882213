const FooterSub = () => {
  return (
    <div className="section-bg">
      <div className="container">
        <div className="footer-pg">
          <div className="footer-flex bg-none text-white">
            <div>© All rights reserved 2024 - PoWDex.io</div>
            <div>
              <span className="me-2">Trademark Policy</span>
              <span> Privacy Policy</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterSub;
