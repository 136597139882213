import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import "./Migrate.css";

const Migrate = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Navigates to the previous page
  };
  return (
    <div className="section-bg-pages section-bg view-positions-pg">
      {" "}
      <div className="gradient-bg-circle-2"> </div>
      <div className="gradient-bg-circle"></div>
      <div className="container">
        <div className="page-width-vp">
          <div className="migrate-pg">
            <div className="migrate-pg-heading">
              <div>
                <i onClick={handleGoBack} className="fa fa-arrow-left"></i>
              </div>
              <div> Migrate v2 liquidity</div>
              <div>
                <i
                  className="fa fa-info-circle"
                  data-tip="Here you can migrate your liquidity from PoWDex V2 to PoWDex V3."
                ></i>
                <Tooltip place="top" effect="solid" />
              </div>
            </div>
            <div className="migrate-pg-body">
              For each pool shown below, click migrate to remove your liquidity
              from PoWDex V2 and deposit it into PoWDex V3.
              <div className="v2-list">No V2 liquidity found.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Migrate;
